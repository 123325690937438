.braft-output-content {
  font-size: 16px;
  line-height: 25px;
  word-break: break-all;
  white-space: pre-wrap;
}
.braft-output-content p {
  min-height: 1em;
}
.braft-output-content .media-wrap {
  width: 100%;
  margin: 0 auto;
}
.braft-output-content .video-wrap,
.braft-output-content .image-wrap {
  width: 100%;
  text-align: center;
}
.braft-output-content .video-wrap video,
.braft-output-content video {
  width: 500px;
  height: 100%;
  max-height: 400px;
  display: block;
  margin: 1em auto;
}
.braft-output-content .image-wrap img,
.braft-output-content img {
  max-width: 60%;
  height: auto;
  display: block;
  margin: 1em auto;
}
.braft-output-content ul,
.braft-output-content ol {
  margin: 16px 0;
  padding: 0;
}
/* .braft-output-content blockquote {
  margin: 0 0 10px 0;
  padding: 15px 20px;
  background-color: #f1f2f3;
  border-left: solid 5px #ccc;
  color: #666;
  font-style: italic;
} */
/* .braft-output-content pre {
  max-width: 100%;
  max-height: 100%;
  margin: 10px 0;
  padding: 15px;
  overflow: auto;
  background-color: #f1f2f3;
  border-radius: 3px;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.braft-output-content pre pre {
  margin: 0;
  padding: 0;
} */
