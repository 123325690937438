@custom-media --small-viewport (max-width: 600px);
@custom-media --large-viewport (min-width: 900px);

:root {
  --color-primary: #1971ff;
  --color-primary-85: #3984fd;
  --color-primary-70: #5a97fc;
  --color-primary-45: #8fb8f9;
  --color-primary-25: #bbd2f7;
  --color-primary-5: #e6edf5;
  --color-primary-hover: var(--color-primary-85);

  /* 成功颜色 */
  --color-success: #07c160;

  /* danger 错误基础色 */
  --color-danger: #f71f22;
  --color-danger-font: #bf3e36;
  --color-danger-font-1: #fc2249;
  --color-danger-font-2: #ffe8ec;

  /* warn 警告基础色 */
  --color-warning: #ff9c00;
  --color-warning-1: #fff1e5;

  /* info 提示基础色 */
  --color-info: #00c6ff;

  /* 黄色 */
  --color-other-yellow: #fbd322;
  /* 紫色 */
  --color-other-purple: #7022ff;
  /* 蓝色 */
  --color-other-blur: #004da0;

  /* font */
  /* 字体主色调 */
  --color-font-black: #19191a;
  /* 字体主色调的85% ..... */
  --color-font-black-85: #3b3b3c;
  --color-font-black-80: #474748;
  --color-font-black-70: #5e5e5e;
  --color-font-black-60: #757576;
  --color-font-black-45: #979798;
  --color-font-black-40: #a3a3a3;
  --color-font-black-25: #c5c5c6;
  --color-font-white: var(--color-white);

  /* background */
  --color-background: #f5f5f5;
  --color-background-2: rgba(25, 113, 255, 0.05);
  --color-background-3: #f5f6f7;
  --color-background-4: #f3f8ff;
  --color-background-5: #4c4c4d;
  --color-background-6: #b1b1b3;
  --color-background-7: #f5f5f7;
  --color-background-8: rgba(255, 255, 255, 0.92);
  --color-background-9: rgba(25, 25, 26, 0.7);
  --color-background-10: rgba(25, 25, 26, 0.05);
  --color-background-11: #fafbfc;
  --color-background-12: #a80000;
  --color-background-13: #646566;
  --color-background-14: rgba(0, 0, 0, 0.2);
  --color-background-15: #f7f8fa;
  --color-background-16: #d8d8d8;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-transparent: transparent;

  /* 按钮字体  */
  --color-btn: #7e7f80;
  /* 文字按钮 */
  --color-btn-text: #b3b3b3;

  /* border边框 */
  --color-border: #dddddd;
  --color-border-1: var(--color-background-13);
  --color-border-2: var(--color-background-16);
  --color-border-3: #ebeced;
  --color-border-4: #ebedf0;
  /* 细线的border */
  --color-fine-border: #e1e1e6;
  /* 模块之间的间距 */
  --color-interval: #f6f6fb;
  /* 禁用颜色 */
  --color-disabled: var(--color-border-3);
  /* box-shadow阴影 */
  --color-box-shadow: 1px 1px 3px 0px var(--color-border-1);
  --color-box-shadow-2: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
  --color-box-shadow-3: 0px 1px 3px 0px rgba(153, 153, 153, 0.05);
  --color-box-shadow-4: 0px 1px 3px 0px var(--color-background-14);
  /* 标题 */
  --color-title: #252526;
  /* 标题2 微信 */
  --color-title-2: #030303;

  /* 副标题 */
  --color-subtitle: #89898c;
  /* 副标题2 微信 */
  --color-subtitle-2: #999999;

  /* 提示信息 */
  --color-placeholder: #bbbbbf;

  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-22: 22px;
  --font-size-20: 20px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;
  --font-size-10: 10px;

  --font-size-title: 18px;
  --font-size-subtitle: 16px;
  --font-size-description: 14px;

  --border-radius: 5px;

  --backgroud-description: rgba(25, 113, 255, 0.15);

  --finish-border: var(--color-title);

  --input-height: 40px;

  --service-btn-background: linear-gradient(180deg, #5a71df 0%, #2a72f5 100%);

  /* Transition variables
-------------------------- */
  --all-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  --fade-transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
  --fade-linear-transition: opacity 200ms linear;
  --md-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
  --border-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  --color-transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  --text-shadow: 1px 1px 3px var(--color-border-1);
}

@media (prefers-color-scheme: dark) {
  html,
  body {
    color: var(--color-font-black);
  }
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

html,
body {
  width: 100%;
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.search-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

/* rc-input输入框 */
.rc-input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
}

.rc-input:focus,
.rc-input:focus-visible {
  outline: none;
}

.rc-input-suffix {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
}

.rc-input-clear-icon {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  line-height: 40px;
  transition: width 0.3s, height 0.3s;
}

.rc-input-clear-icon-hidden {
  display: none;
}

/* rc-input结束 */

.iconImage {
  vertical-align: top;
  width: 22px;
  height: 22px;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
    PingFang SC, "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  background-color: var(--color-background-7);
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--color-font-black-70);
  &:hover {
    color: var(--color-primary);
    /* font-weight: 600; */
  }
}


.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.__nav {
  background-color: var(--color-primary);
  height: 48px;
  line-height: 48px;
}

.__nav-placeholder {
  height: 48px;
}

.__nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  color: var(--color-white);
}

.__nav ul li {
  margin: 0 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.__nav ul li .link {
  height: 100%;
  width: 100%;
  display: inline-block;
  color: var(--color-white);
}

.__nav ul li .link:hover {
  color: var(--color-white);
}

.__nav ul li.active {
  font-weight: 600;
}

.__nav ul li.active::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-white);
  border-radius: 2px;
}

.tab_item {
  width: 122px;
  height: 44px;
  border-radius: 25px;
  line-height: 44px;
  border: 1px solid var(--color-border-2);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-font-black-45);
  margin-right: 20px;
  cursor: pointer;
  background-color: var(--color-transparent);
}

.tab_item.tab_item_active {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  font-weight: 600;
}

.button {
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  color: var(--color-white);
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.button.paddingbtn {
  padding: 6px 34px;
}

.button:hover {
  background-color: var(--color-primary-hover);
}

.status_btn {
  margin-top: 5px;
  color: var(--color-white);
  font-size: 14px;
  min-width: 78px;
  padding: 6px 0;
  font-weight: 600;
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.2s ease-in;
  /* 禁止中文换行 */
  white-space: nowrap;
}

.status_btn:hover {
  background-color: var(--color-primary-hover);
}

.status_btn[data-btn-status="1"],
.status_btn[data-btn-status="9"] {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.status_btn[data-btn-status="3"] {
  background-color: var(--color-success);
  color: var(--color-white);
}

.status_btn[data-btn-status="4"] {
  background-color: var(--color-background-15);
  color: var(--color-background-13);
}

.status_btn[data-btn-status="10"] {
  background-color: var(--color-danger-font-2);
  color: var(--color-danger-font-1);
}

.status_btn[data-btn-status="11"] {
  background-color: var(--color-warning-1);
  color: var(--color-warning);
}

.noImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-6);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.visually_hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  left: -9999px;
}

.overflow-hidden {
  overflow: hidden;
}
.transition-all {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.relative {
  position: relative;
}
.pointer {
  cursor: pointer;
}

.SwiperButton {
  position: absolute;
  top: 42%;
  padding: 15px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  color: var(--color-white);
  border: 1px solid var(--color-transparent);
  transition: all 0.2s ease-in-out;
  user-select: none;
}
.SwiperButton:hover {
  background-color: #646566;
}
.SwiperButton.SwiperButtonDisabled {
  background-color: #c8c9cc;
  cursor: not-allowed;
}

.SwiperPrev {
  left: 0;
  transform: translate(-120%, -50%);
}

.SwiperNext {
  right: 0;
  transform: translate(120%, -50%);
}

.swiper-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-block: 20px;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: -0.1em;
  font-size: 12px;
}